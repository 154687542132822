import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { SvgIcon } from '@mui/material'
import {
  addArticleToTemplateGraphQL,
  addCartTemplateItemsToCartTemplateGraphQL,
  addCartTemplatesToCartTemplateGraphQL,
  addOfferToCartTemplateGraphQL,
  addOrderItemsToCartTemplateGraphQL,
  createNewTemplateGql,
} from '@obeta/data/lib/actions/template-actions'
import { ReactComponent as TemplateIcon } from 'assets/icon/designsystem/playlist_add.svg'
import { useDoubleSort } from '@obeta/data/lib/hooks/useDoubleSort'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useLocalSearch } from '@obeta/data/lib/hooks/useLocalSearch'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useProductCollectionActions } from '@obeta/data/lib/hooks/useProductCollectionActions'
import { useSearchText } from '@obeta/data/lib/hooks/useSearchText'
import { useWindowScroll } from '@obeta/data/lib/hooks/useWindowEvent'
import {
  CartTemplateItemInput,
  DropDownOriginCartTemplate,
  DropDownTemplatesOffer,
  DropdownTemplatesType,
} from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { PopoverProps } from '../dropdown-button/PopoverDropdownMenu'
import { CartTemplate } from '@obeta/schema'
import styles from './DropdownTemplates.module.scss'
import { EntityDropdown, EntityDropdownSearchField } from '../entity-dropdown/EntityDropdown'
import { DropdownButton } from '../dropdown-button'
import { namesSortComparator } from '@obeta/utils/lib/namesSortComparator'
import { trackClick } from '@obeta/utils/lib/tracking'
import { withUnit } from '@obeta/utils/lib/withUnit'
import { DropDownTemplatesOrder } from '@obeta/models/lib/models/Order/OrderV2'

export interface IDropdownTemplatesProps
  extends Omit<IDropdownTemplateBaseProps, 'dropdown' | 'dropdownId'>,
    Pick<IDropdownTemplateBaseProps['dropdown'], 'anchorOrigin' | 'transformOrigin'> {
  disabled?: boolean
  selectedArticleCount?: number
  withTitle?: boolean
  withEndIcon?: boolean
  withZeroBasedArticleCount?: boolean
  autoFlexed?: boolean
}

const keys = ['id', 'userId', 'name', 'updatedAt']
export const DropdownTemplates: React.FC<IDropdownTemplatesProps> = (props) => {
  const {
    productsToAdd,
    anchorOrigin,
    mobile,
    desktop,
    transformOrigin,
    disabled,
    selectedArticleCount,
    withTitle = desktop,
    withEndIcon = !mobile,
    withZeroBasedArticleCount,
    autoFlexed,
    templatesType,
    offer,
    order,
    originCartTemplate,
    onAddToCartTemplate,
  } = props
  const { t } = useTranslation()
  const { handleClick, ...dropdown } = usePopoverState()
  const buttonId = 'templates-button'

  let withCount = ''
  if (selectedArticleCount) {
    withCount = `(${selectedArticleCount})`
  } else if (withZeroBasedArticleCount) {
    withCount = '(0)'
  }

  let title = withCount
  if (withTitle) {
    title = withUnit(t<string>('SHOPPING_CART.ACTIONS.TEMPLATE_desktop'), withCount)
  }

  return (
    <DropdownButton
      title={title}
      withEndIcon={withEndIcon}
      buttonId={buttonId}
      dropdownId={'templates-menu'}
      expanded={dropdown.open}
      disabled={disabled}
      onClick={handleClick}
      startIcon={<SvgIcon component={TemplateIcon} />}
      justifyContent="spaceBetween"
      className={autoFlexed ? styles.autoFlex : ''}
    >
      <DropdownTemplatesBase
        buttonId={buttonId}
        dropdown={{ ...dropdown, anchorOrigin, transformOrigin }}
        mobile={mobile}
        productsToAdd={productsToAdd}
        offer={offer}
        order={order}
        originCartTemplate={originCartTemplate}
        templatesType={templatesType}
        onAddToCartTemplate={onAddToCartTemplate}
      />
    </DropdownButton>
  )
}

export interface IDropdownTemplateBaseProps {
  dropdown: Pick<PopoverProps, 'onClose' | 'open' | 'anchorEl' | 'transformOrigin' | 'anchorOrigin'>
  productsToAdd: CartTemplateItemInput[] | (() => CartTemplateItemInput[])
  buttonId?: string
  mobile: boolean
  desktop?: boolean
  idOfTemplateToHide?: string
  originTemplate?: { id: string; name: string }
  offer?: DropDownTemplatesOffer
  order?: DropDownTemplatesOrder
  originCartTemplate?: DropDownOriginCartTemplate
  templatesType: DropdownTemplatesType
  onAddToCartTemplate?: (id: string) => void
}

export const DropdownTemplatesBase: React.FC<IDropdownTemplateBaseProps> = (props) => {
  const {
    idOfTemplateToHide,
    productsToAdd,
    dropdown,
    buttonId,
    mobile,
    originTemplate,
    offer,
    order,
    originCartTemplate,
    templatesType,
    onAddToCartTemplate,
  } = props
  const { t } = useTranslation()

  const query = useMemo(
    () => ({
      selector: {
        id: {
          $ne: idOfTemplateToHide,
        },
      },
    }),
    [idOfTemplateToHide]
  )

  const templates = useEntities<CartTemplate>(
    'carttemplates',
    idOfTemplateToHide ? query : undefined
  )

  const { searchText, setSearchText } = useSearchText()
  const dispatch = useDispatch()
  const sortedTemplates = useDoubleSort(
    templates,
    (t) => {
      return t.itemCount > 0 ? -1 : 1
    },
    (a, b) => {
      return namesSortComparator(a.name, b.name)
    }
  )

  const { result } = useLocalSearch<CartTemplate>(sortedTemplates, searchText, keys)
  const { onAddToTemplate } = useProductCollectionActions()

  // Close dropdown on window scroll
  useWindowScroll(() => {
    dropdown.onClose()
  }, [dropdown])

  /**
   * Determine which dispatch should be called (Add article to template or add cart templates to cart template)
   * @param option Selected option
   * @returns Dispatch add article to template or add cart templates to cart template and closes dropdown
   */
  const handleOptionSelected = (option: { id: string; name: string; count: number }) => {
    const itemsToAdd = typeof productsToAdd === 'function' ? productsToAdd() : productsToAdd
    if (!itemsToAdd.length && !offer && !originCartTemplate) {
      console.warn('Add products to template: no products to add')
      return
    }

    onAddToCartTemplate && onAddToCartTemplate(option.id)

    trackClick('dropdown-templates-handle-option-selected', {
      cartTemplateId: option.id,
      name: option.name,
      count: option.count,
    })

    switch (templatesType) {
      case DropdownTemplatesType.ADD_ARTICLES:
        dispatch(
          addArticleToTemplateGraphQL(option.name, {
            templateId: option.id,
            itemsToAdd,
          })
        )
        break
      case DropdownTemplatesType.ADD_TEMPLATES:
        originTemplate &&
          dispatch(
            addCartTemplatesToCartTemplateGraphQL(
              {
                originCartTemplateIds: [originTemplate?.id],
                targetCartTemplateId: option.id,
              },
              [originTemplate?.name],
              option.name
            )
          )
        break
      case DropdownTemplatesType.ADD_TEMPLATE_ITEMS:
        originCartTemplate &&
          dispatch(
            addCartTemplateItemsToCartTemplateGraphQL(
              {
                originCartTemplateId: originCartTemplate?.cartTemplateId,
                targetCartTemplateId: option.id,
                exclude: originCartTemplate.multiSelection.exclude,
                include: originCartTemplate.multiSelection.include,
                search: originCartTemplate.multiSelection.search,
              },
              originCartTemplate?.cartTemplateName,
              originCartTemplate.selectedItemCount,
              option.name
            )
          )
        break
      case DropdownTemplatesType.ADD_OFFER:
        offer &&
          dispatch(
            addOfferToCartTemplateGraphQL(
              {
                amount: offer.modifiedOfferItemAmounts,
                cartTemplateId: option.id,
                exclude: offer.multiSelection.exclude,
                include: offer.multiSelection.include,
                offerId: offer.offerId,
                search: offer.multiSelection.search,
              },
              offer.offerName,
              offer.selectedItemCount,
              option.name
            )
          )
        break
      case DropdownTemplatesType.ADD_ORDER_ITEMS:
        order &&
          dispatch(
            addOrderItemsToCartTemplateGraphQL(
              {
                amount: order.modifiedOrderItemAmounts,
                cartTemplateId: option.id,
                exclude: order.multiSelection.exclude,
                include: order.multiSelection.include,
                orderId: order.id,
                search: order.multiSelection.search,
              },
              order.name,
              order.selectedItemCount,
              option.name
            )
          )
        break
      default:
        break
    }
    onAddToTemplate(itemsToAdd.map((item) => item.productId)?.[0])
    dropdown.onClose()
  }

  return (
    <EntityDropdown
      buttonId={buttonId}
      createEntityTitle={t<string>('ARTICLE_DETAIL.CREATE_NEW_TEMPLATE')}
      mobile={mobile}
      options={result.map((template) => {
        return {
          id: template.id,
          name: template.name,
          count: template.itemCount,
        }
      })}
      topAdornment={<EntityDropdownSearchField mobile={mobile} onTextUpdated={setSearchText} />}
      onEntityCreated={(name) => {
        dispatch(createNewTemplateGql(name))
      }}
      onOptionSelected={handleOptionSelected}
      {...dropdown}
      onClose={() => {
        dropdown.onClose()
        setSearchText('')
      }}
    />
  )
}
